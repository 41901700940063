import React, {Component} from 'react'

class Dimora extends Component{
    
    render(){
        return(
            <div>
            <div className="App-Container-Cover"></div>    
            <div >
               <p>Dimora</p>  
            </div>
           
            </div>
        )
    }
}

export default Dimora