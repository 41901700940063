import React,{Component} from 'react'

class Footer extends Component{
    constructor(){
        super()
        this.state = {
            year: new Date().getFullYear()
        }
    }
    render(){
        return(
            <footer className="App-footer">
               <small> © {this.state.year} Guado Arciduca | Privacy Policy</small> 
            </footer>
        )
    }
}

export default Footer