import React,{Component} from 'react'
import { Link } from 'react-router-dom'
import logo from '../Images/logo.png'


class Header extends Component{
    constructor(){
        super()
        this.state = {
            menu: 'menu'
        }
    }
    
    render(){
const isDesktop = /Windows|Macintosh|X11|Ubuntu|Linux/i.test(navigator.userAgent)
const isMobile = /Android|iPhone|iPad/i.test(navigator.userAgent)
        return (
            <div>
            { isDesktop &&
                <header className="App-header">  
                <span className="App-header-logo">
                <img src={logo} alt="logo dimora guado arciduca"/>
                </span>
                <span>
                <span><Link className="App-link" to="/">Home</Link></span>
                <span><Link className="App-link" to="/dimora">Dimora</Link></span>
                <span><Link className="App-link" to="/progetto">Progetto</Link></span>
                <span><Link className="App-link" to="/lusso_design">Lusso & Design</Link></span>
                <span><Link className="App-link" to="/collocazione">Collocazione</Link></span>
                <span><Link className="App-link" to="/eventi">Eventi</Link></span>
                <span><Link className="App-link" to="/contatti">Contatti</Link></span>
                </span> 
               </header>
                }
                { isMobile &&
                <header className="App-header">  
                    <span className="App-header-logo">
                    <img src={logo} alt="logo dimora guado arciduca"/>
                    </span>
                    <span></span>
                <span className="menu-mobile">
                    <span className="dot"></span>
                    <span className="dot"></span>
                    <span className="dot"></span>
                </span>
                </header>
                }          
            </div>
        )
    }
}

export default Header