import React,{Component} from 'react'
import logo from './logo-single.png';

class Home extends Component{
    render(){
        const isDesktop = /Windows|Macintosh|X11|Ubuntu|Linux/i.test(navigator.userAgent)
        const isMobile = /Mobile|Android|iPhone|iPad/i.test(navigator.userAgent)
        return (
            <div className="App-Container Home">
         <img src={logo} className="Home-logo" alt="logo" />
        <p>
        « APOLLO, PROTETTORE DELLE MUSE ED ISPIRATORE DI OGNI ARTE,
ABBANDONAVA LA SUA DELO PER RIFUGIARSI NELLA REGIONE AL DI LÀ DI BOREAS,
IL VENTO FREDDO DEL NORD EST A NOI NOTO COME BORA.
PER QUESTO SI DEFINIRONO IPERBOREI GLI ABITANTI CHE, SI DICEVA,
POPOLASSERO QUESTA TERRA DAL CLIMA DOLCE E MITE E, CON LO STESSO NOME,
SI CHIAMARONO GLI ARTISTI CHE LA ABITAVANO, GODENDO DELLA PROTEZIONE DEL NUME »
        </p>
      </div>
        )
    }
}

export default Home