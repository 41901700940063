import React from 'react';
import { BrowserRouter as Router} from 'react-router-dom'
import { Switch, Route} from 'react-router-dom'
import './App.css';
import Header from './Components/Commons/Header';
import Home from './Components/Home'
import Footer from './Components/Commons/Footer';
import Dimora from './Components/Dimora';

function App() {
  return (
    <div className="App">
      <Router>
      <Header />
      <Switch>
        <Route exact path="/"component={Home} />
        <Route exact path="/dimora"component={Dimora} />
      </Switch>
      <Footer />
      </Router>
    </div>
  );
}

export default App;
